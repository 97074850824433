<template>
  <div>
    <v-row>
      <v-col cols="12">
        The branch is {{ myBranch.Status }}
        <vc-change-status
          v-if="myBranch.Status == 'online'"
          @refetch-data="GetBransh"
          :ObjectId="myBranch.Id"
          TableName="branches"
          Status="offline"
          :WithDialog="true"
        />
        <vc-change-status
          v-if="myBranch.Status == 'offline'"
          @refetch-data="GetBransh"
          :ObjectId="myBranch.Id"
          TableName="branches"
          Status="online"
          :WithDialog="true"
        />
      </v-col>

      <v-col cols="12">
        <branch-order />
      </v-col>
      </v-row
    >
  </div>
</template>

<script>
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import { GetById } from '@core/api/Branch'
import { Create } from '@core/api/Chat'
import { getInfo } from '@core/api/User'
import { onMounted, ref } from '@vue/composition-api'
import BranchItem from './Components/BranchItem.vue'
import BranchOrder from './Components/BranchOrder.vue'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { BranchOrder, BranchItem, VcChangeStatus },
  setup() {
    const myBranch = ref({})
    const userInfo = ref({})
    const snackbar = ref(false)
    const Text =ref('')
    const subscribtion = ref({})
    const server = {
      P256dh: '',
      EndPoint:'',
      Auth:'',
      BranchId: undefined,
    }

const logging = v => {
  console.log('vparse',v)
  let x = JSON.parse( JSON.stringify(v))
  console.log('xxxxxx', x.endpoint, '          keys',x.keys.P256dh,'          auth',x.keys.auth)
}
    // create client keys
     async function subscribe() {
         let sw = await navigator.serviceWorker.ready;
         console.log('sw ')
         let push = await sw.pushManager.subscribe({
           userVisibleOnly: true,
           applicationServerKey: 'BKnJ-Z1VDCMXMxMlZFaihoo13O0A-dFHo1KTXNfA7xKxy71ite8JGdy6znJgzFF2TdUo-ifVj3fzUDgfbh6VTtI',
         })
         logging(push)
         let x = JSON.parse( JSON.stringify(push))
          server.Auth = x.keys.auth
          server.P256dh =  x.keys.p256dh
          server.EndPoint = x.endpoint
          console.log('server', server)
          if (userInfo.value.BranshId != null ){
          server.BranchId = userInfo.value.BranshId
          Create(server)
          }
       }
      const GetBransh = () => {
      GetById({ Id: JSON.parse(localStorage.getItem('userData')).BranshId })
        .then(response => {
          // handle success
          myBranch.value = response
          // remove loading state
        })
        .catch(error => {
          console.log(error)
        })
    }
      const info = () => { getInfo().then( res => {
        userInfo.value = res
        console.log('userInfo getinfo',userInfo.value)
        if (res.BranshId !=null) {
          subscribe()
        }
    }
    )}

    onMounted(() => {
      if ('serviceWorker' in navigator) {
        addEventListener('load', async () => {
          let sw = await navigator.serviceWorker.register('./sw.js')
          console.log('service worker listener', sw);
        });
      }
      GetBransh()
      info()

    })

    return {
      subscribtion,
      userInfo,
      info,
      myBranch,
      GetBransh,
      server,
      Text,
      snackbar,
      subscribe,
      }
  },
  data() {
    return {
      timerCount: 60,
    }
  },

  // watch: {
  //   timerCount: {
  //     handler(value) {
  //       if (value > 0) {
  //         setTimeout(() => {
  //           this.timerCount--
  //         }, 1000)
  //       }
  //       if (value == 0) {
  //         location.reload()
  //         this.timerCount = 60
  //       }
  //     },
  //     immediate: true, // This ensures the watcher is triggered upon creation
  //   },
  // },
}
</script>
