import { GetByBranchId, GetStatusFinished } from '@core/api/Order'
import { DateTimeFormatter, Instant, LocalDateTime } from '@js-joda/core'
import { mdiCart, mdiCurrencyUsd } from '@mdi/js'

import { ref, watch } from '@vue/composition-api'

export default function useOrderList() {
  // Table Handlers
  const tableColumns = [
    { text: 'id', value: 'Id' },
    { text: 'Customer Name', value: 'Name' },
    { text: 'Phone', value: 'PhoneNumber'},
    { text: 'Creation Time', value: 'CreationTime' },
    { text: 'Amount', value: 'TotalAmount' },
    { text: 'Status', value: 'Status', sortable: true },
    { text: 'Action', value: 'Action', sortable: false },
  ]

  const OrderListTable = ref([])
  const OrderListTablef = ref([])
  const expanded = ref([])
  const searchQuery = ref('')
  const searchDateFrom = ref(new Date())
  const searchDateTo = ref(new Date())
  const statusFilter = ref(null)
  const orderSourceFilter = ref(null)
  const totalOrderListTable = ref(0)
  const totalOrderListTablef = ref(0)
  const pindCount = ref(0)
  const OrderTotalLocal = ref([])
  const BeatLocation = require('@/assets/AlarmSound.mp3')
   const PlaySound = () => {
     let beat = new Audio(BeatLocation);
     //beat.pause();
    // beat.play();
    // beat.autoplay()
   }
  searchDateFrom.value.setHours(0, 0, 0, 0)
  searchDateTo.value.setHours(23, 59, 59, 999)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [true],
  })
  const loading = ref(false)

    // Pause/stop the beat


    // Reload the beat (back to the start)
   // beat.load();

  const fetchOrders = () => {
    pindCount.value = 0

    const BranshId = JSON.parse(localStorage.getItem('userData')).BranshId
    GetByBranchId({
      BranchId: BranshId,
      Any: searchQuery.value,
      OrderSource: orderSourceFilter.value,
    })
      .then(response => {
        response.Items.forEach((i, index) => {
if ( i .Status == 'pinding' ) { pindCount.value = pindCount.value + 1}

          i.OrderTransations.forEach((item, index) => {
             if(item.Addon != ''){
              item.Addon = JSON.parse(item.Addon)
              item.Addon2 = item.Addon
             }
            })
        })
        // handle success
        OrderListTable.value = response.Items
        totalOrderListTable.value = response.length
        OrderTotalLocal.value = response.Totals
        PlaySound()
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        loading.value = false

        console.log(error)
      })
  }
  const fetchFinishedOrders = () => {
    const BranshId = JSON.parse(localStorage.getItem('userData')).BranshId
    GetStatusFinished({
      BranchId: BranshId,
      Any: searchQuery.value,
      Status: statusFilter.value,
      OrderSource: orderSourceFilter.value,

      DateFrom: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateFrom.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
      DateTo: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateTo.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
    })
      .then(response => {
        // handle success
        response.Items.forEach((i, index) => {
          i.OrderTransations.forEach((item, index) => {

             if(item.Addon != ''){
              item.Addon = JSON.parse(item.Addon)
              item.Addon2 = item.Addon
             }
            })
        })
        OrderListTablef.value = response.Items
        totalOrderListTablef.value = response.length
        OrderTotalLocal.value = response.Totals
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, orderSourceFilter, options, searchDateFrom, searchDateTo], () => {
    loading.value = true
    fetchOrders()
    fetchFinishedOrders()
  })
  const resolveTotalIcon = total => {
    if (total === 'Count') return { icon: mdiCart, color: 'primary' }
    if (total === 'Amount') return { icon: mdiCurrencyUsd, color: 'success' }
    return { icon: mdiCart, color: 'primary' }
  }
  return {
    pindCount,
    PlaySound,
    BeatLocation,
    fetchFinishedOrders,
    fetchOrders,
    expanded,
    tableColumns,
    searchQuery,
    options,
    OrderListTable,
    OrderListTablef,
    statusFilter,
    totalOrderListTable,
    totalOrderListTablef,
    loading,
    searchDateFrom,
    searchDateTo,
    orderSourceFilter,
    OrderTotalLocal,
    resolveTotalIcon,
  }
}
