<template>
  <v-row>
    <v-col cols="12">
      <!-- order total card -->
       <!-- :src="require('@/assets/AlarmSound.mp3')"
  :currentSrc="require('@/assets/AlarmSound.mp3')" -->

      <!-- <audio id="AlarmSound">
  <source
  src="require(AlarmSound.mp3')"
 type="audio/mpeg"
 >
  Your browser does not support the audio element.
</audio> -->
<div :key="AudioKey" v-if="pindCount > 0">
<audio id="myAudio" autoplay loop>
  <!-- <source src="AlarmSound.ogg" type="audio/ogg"> -->
  <source :src="require('@/assets/AlarmSound.mp3')" type="audio/mpeg">

</audio>
</div>

      <!-- Main Card -->
      <app-card-actions @refresh="fetchOrders">
        <template slot="title"> Orders List </template>

        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="OrderListTable"
          item-key="Id"
          :items-per-page="-1"
          :options.sync="options"
          :expanded.sync="expanded"
          show-expand
          :loading="loading"
          :sort-desc="[true]"
        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
          <!-- Name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
          <!-- CreationTime -->
          <template #[`item.CreationTime`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{
                  filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
                }}</span>
              </div>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).title
              }}
            </v-chip>
          </template>
           <template #[`item.TotalAmount`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.TotalAmount.toFixed(3) }} JD</span>
              </div>
            </div>
          </template>
          <!-- Action -->
          <template #[`item.Action`]="{ item }">
            <vc-change-status
              v-if="item.Status == 'pinding'"
              @refetch-data="fetchOrders"
              :ObjectId="item.Id"
              TableName="orders"
              Status="accept"
              :WithDialog="false"
            />
            <vc-change-status
              v-if="item.Status == 'pinding'"
              @refetch-data="fetchOrders"
              :ObjectId="item.Id"
              TableName="orders"
              Status="cancelled"
              :WithDialog="true"
            />
            <v-row v-if="item.Status == 'accept'" class="mt-2 mb-2">
              <v-col cols="7">
             <vc-change-status
              @refetch-data="fetchOrders"
              :ObjectId="item.Id"
              :WithDialog="false"
              TableName="orders"
              Status="finished"
            />
              </v-col>
              <v-col cols="5">
                 <drawer-print Type="Order" :Data="item" />
              </v-col>
            </v-row>
          </template>
          <!-- expandad data -->
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length / 2">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="6" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Order Items</h3></span>
                    </v-col>
                    <v-col lg="6" md="8" sm="8" cols="8"> </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>
                <v-row v-for="(orderTransation, i) in item.OrderTransations" :key="i">
                  <v-col lg="7" cols="7"
                    >{{ orderTransation.Name }}
                    <br />
                    <span v-for="(Addon, i) in orderTransation.Addon2" :key="i"
                      >Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                    /></span>
                    <span v-if="orderTransation.Description != ''"
                      >Note : {{ orderTransation.Description }} <br /></span>
                  </v-col>
                  <v-col lg="2" cols="2">
                    {{ orderTransation.Qty }}
                  </v-col>
                  <v-col>
                    {{ (parseFloat(orderTransation.TotalPrice) * orderTransation.Qty).toFixed(2) }}
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-1"></v-divider>

                <v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0).toFixed(2)
                      }}
                      JOD
                    </span>
                  </v-col></v-row
                >
                <v-divider class="mt-1 mb-1"></v-divider>
                <p v-if="item.Description != null" class="mb-1">Description: {{ item.Description }}</p>

                <p class="mb-1">DeliveryPrice: {{ item.DeliveryPrice.toFixed(3) }} JD</p>
                <p v-if="item.PromoCode != null" class="mb-1">PromoCode: {{ item.PromoCode }}</p>
                <p v-if="item.PromoCode != null" class="mb-1">PromoCodeAmount: {{ item.PromoCodeAmount }}</p>
                <p class="mb-1">TotalAmount: {{ item.TotalAmount }}</p>
              </v-card>
            </td>
            <td :colspan="headers.length / 2">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="12" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Customer & Address</h3></span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>

                <p class="my-1">Name: {{ item.Name }}</p>
                <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
                <p class="mb-1">Order Type: {{ item.OrderType }}</p>
                <p v-if="item.ScheduleTimeOrder > '2020-01-01 02:00:00.0000000'" class="mb-1">
                  Schedule Time Order: {{ filter.parseTime(item.ScheduleTimeOrder, '{y}-{m}-{d} {h}:{i}') }}
                </p>
                <p class="mb-1">Delivery Type: {{ item.DeliveryType }}</p>
                <p v-if="item.DeliveryCompany != null" class="mb-1">Delivery Company: {{ item.DeliveryCompany }}</p>
                <p class="mb-1">Order Source: {{ item.OrderSource }}</p>
                <p v-if="item.OrderSourceRefNumber != null" class="mb-1">
                  Order Source Ref Number: {{ item.OrderSourceRefNumber }}
                </p>

                <p v-if="item.City != null" class="mb-1">City: {{ item.City }}</p>
                <p v-if="item.BranshAddress != null" class="mb-1">Bransh Address: {{ item.BranshAddress }}</p>
                <p v-if="item.AddressDetail != null" class="mb-1">Address Details: {{ item.AddressDetail }}</p>
                <p v-if="item.BuildingNo != null" class="mb-1">Building No: {{ item.BuildingNo }}</p>
                <p v-if="item.StreetNo != null" class="mb-1">Street Name: {{ item.StreetNo }}</p>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </app-card-actions>
    </v-col></v-row
  >
</template>

<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import DrawerPrint from '@/components/vc-print/DrawerPrint.vue'
import pushSubscription from '@/components/vc-push/pushSubscription'
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import { parseTime } from '@core/utils/filter'
import { mdiCalendarRange, mdiClockOutline, mdiPrinter } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Pusher from 'pusher-js'
import useOrderList from './useOrderList'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { VcChangeStatus, AppCardActions, DrawerPrint },
  setup() {
    //var x = document.getElementById("AlarmSound");
    const AudioKey = ref(true)

    const playAudio = () => {
      var x =document.getElementById("myAudio").autoplay

   // document.getElementById("demo").innerHTML = x;
    }

   //const isPlaying = audelem => { return !audelem.paused; }
      const messages = ref([]);
      const {
      checkUrl
    } = pushSubscription()
    const {
      PlaySound,
      fetchOrders,
      OrderListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalOrderListTable,
      loading,
      expanded,
      searchDateFrom,
      searchDateTo,
      orderSourceFilter,
      OrderTotalLocal,
      pindCount,
      resolveTotalIcon,
    } = useOrderList()
    const BranchId = JSON.parse(localStorage.getItem('userData')).BranshId
    const counter = ref(0)
    const ch = user => {
      if (BranchId == user) {
        counter.value = counter.value + 1;

       // AudioKey.value = !AudioKey.value
        console.log('counter.value', counter.value)

        console.log(' ch ch ch', counter.value, 'hffff', AudioKey.value)
       // playAudio()
        //checkItems()
    }
    }
    const channelSubscribe = () => {
      let result = checkUrl()
      Pusher.logToConsole = true;
        const pusher = new Pusher('01b4d5c8507d46578a54', {
        cluster: 'ap2'
        });
      if(result == false) {
        pusher.disconnect();
      }
      else {
        const channel = pusher.subscribe('my-channel');
        channel.bind('my-event', data => {
        messages.value.push(data);
        fetchOrders()
        //PlaySound()
        console.log('branch id', BranchId,'username', data.username)
        let user = data.username
          ch(user)
      });
      }
    }

    const checkItems = () => {
    // let arr = JSON.parse(JSON.stringify(OrderListTable.value))
    let isPinding = OrderListTable.value.filter(x => x.Status === 'pinding')
    console.log('length.length',isPinding )
    if (pindCount.value > 0){
      //counter.value =  counter.value +1
      console.log('counter.value', counter.value)
    }
    else{
      counter.value = 0;
      AudioKey.value = !AudioKey.value
     // x.value.pause();
    }
    }
    const checkIWithFetch = () => {
      fetchOrders()
      checkItems()
      console.log('valuevaluevaluevaluevaluevalue.value')

    }
    onMounted(() => {
      channelSubscribe()
      fetchOrders()
    })
    addEventListener('focus', (event) => {
      fetchOrders()
     });
    return {
      pindCount,
      ch,
      checkIWithFetch,
      checkItems,
      counter,
      BranchId,
      playAudio,
      AudioKey,
      PlaySound,
      channelSubscribe,
      checkUrl,
      messages,
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalOrderListTable,
      OrderListTable,
      loading,
      expanded,
      fetchOrders,
      searchDateFrom,
      searchDateTo,
      orderSourceFilter,
      OrderTotalLocal,
      resolveTotalIcon,
      filter: { parseTime },
      icons: {
        mdiPrinter,
        mdiClockOutline,
        mdiCalendarRange,
      },
    }
  },
}
</script>
